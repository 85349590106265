/* eslint-env jquery */

"use strict";

window.addEventListener("load", () => {
    const Swiper = require("swiper").default;
    const Navigation = require("swiper").Navigation;
    const Pagination = require("swiper").Pagination;

    if (!window.partnersSwiperInitialized) {
        const carousels = $(".partners-carousel-swiper");

        carousels.each(function (index, carousel) {
            const selector = "." + $(carousel).data().id + " .swiper";
            const prevButton = "." + $(carousel).data().id + " .swiper-button-prev";
            const nextButton = "." + $(carousel).data().id + " .swiper-button-next";
            const paginationSelector = "." + $(carousel).data().id + " .swiper-pagination";

            // eslint-disable-next-line no-unused-vars
            const partnerSwiper = new Swiper(selector, {
                loop: true,
                modules: [Navigation, Pagination],
                pagination: {
                    el: paginationSelector,
                    clickable: true,
                },
                navigation: {
                    nextEl: nextButton,
                    prevEl: prevButton,
                },
                breakpoints: {
                    0: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 8,
                        spaceBetween: 20,
                    },
                },
            });
        });
        window.partnersSwiperInitialized = true;
    }
});
